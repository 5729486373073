@import 'src/styles/colorVars';
@import 'src/styles/numbers';

$tabletSmall: 1025px;
$tabletLarge: 1300px;

.container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    margin-bottom: 5px;
    max-width: 80%;

    @media (min-width: $mobile_sm_width) and (max-width: $tabletSmall) {
        max-width: 65%;
    }

    @media (min-width: $tabletSmall) and (max-width: $tabletLarge) {
        max-width: 80%;
    }

    @media (min-width: #{$tabletLarge + 1}) and (max-width: $desktop_width) {
        max-width: 75%;
    }

    @media (min-width: $desktop_width) {
        max-width: 65%;
    }

    &.bulkWithRichParams {
        max-width: 336px;
    }
}

.buttonsWrapper {
    border-radius: 8px;
    padding: 5px 0px;
    display: inline-block;
    min-width: 100px;
    width: 100%;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
    background: var(--background-color);
}

.buttonsBody {
    padding: 0px 10px;
    right: 0;
    position: relative;
    width: 100%;
    margin-bottom: 0;
    letter-spacing: 0;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    color: $primary_white;

    &.rtl {
        direction: rtl;
    }
}

.button {
    background: var(--background-color, $secondary_green_bg);
    border-radius: 8px;
    border: none;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
    letter-spacing: 0;
    font-size: 14px;
    word-wrap: break-word;
}

.text {
    margin: 0;
    color: $primary_white;
}

.timestampContainer {
    padding: 0px 10px;
    right: 0;
}

.receiver {
    position: relative;
    float: right;
    margin-bottom: 2px;
    width: 100%;
    bottom: -4px;
    right: -2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.timestampUser {
    letter-spacing: 0;
    position: relative;
    bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    word-wrap: break-word;
    margin-bottom: 0;
    width: 70px;
    right: 3px;
    color: $primary_white;

    display: flex;
    justify-content: flex-end;
}
