@import "src/styles/colorVars.scss";
@import "src/styles/numbers";

.userInfoData {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.15px;
  color: $secondary_black;
  overflow: hidden;
  white-space: nowrap;
}

.userInfoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.photoWrapper {
  position: relative;
}

.userInfoAvatar {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  object-fit: cover;
}

.userInfoAvatarSmall {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 25px;
  right: 0px;
}

.userInfoName {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.33px;
  color: #000000;

  &.inverted {
    color: $primary_white;
  }

  :global {
    .inverted {
      color: $primary_white;
    }
  }
}

.hidden {
  visibility: hidden;
}

.userInfoBack {
  display: none !important;
}

.userInfo .resolveChatContainer {
  top: 72px;
  flex-direction: column;
  max-height: 100px;
  right: 10px;
  width: auto;
  height: auto;
}

.userInfoChatImportance {
  width: 32px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.extraActionsWrapper {
  display: flex;
  align-items: center;
  margin-left: 16px;
  gap: 0px;
}

.moreButtonMobile {
  display: block;

  svg {
    width: 24px;
    height: 24px;
    fill: $secondary_black;
  }

  &.inverted {
    svg path {
      fill: $tertiary_black;
    }
  }
}

.openMenuIcon {
  width: 32px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  svg {
    width: 24px;
    height: 24px;
  }

  svg path {
    fill: $primary_black;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(196, 196, 196, 0.3);
  }
}

.searchIcon svg {
  width: 20px;
  height: 20px;
}

.menuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 180px;
}

.menuIcon {
  margin-right: 12px;
}

.menuIcon svg{
  width: 16px;
  height: 16px;
}

.errorIcon svg path{
  fill:$primary_red
}

.alertText {
  color: $primary_red !important;
}

.mobileActionBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;

  &.inverted {
    .iconMore {
      svg path {
        fill: $primary_white;
      }
    }
    img {
      filter: brightness(0) invert(1);
    }
  }

  &.darken {
    .iconMore {
      svg path {
        fill: $primary_black;
      }
    }
    img {
      filter: brightness(50%);
    }
  }
}

.icon {
  height: 32px;
  width: 32px;
  border: 1px solid $antd_border;
  border-radius: 4px !important;
  background: transparent !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.iconMore {
  svg {
    width: 24px;
    height: 24px;
    fill: $secondary_black;
  }

  &.inverted {
    svg path {
      fill: $tertiary_black;
    }
  }
}

.rightModuleDrawer {
  
  [class="ant-drawer-body"] {
    padding: 0px;
  }
  [class="ant-drawer-content-wrapper"] {
    max-width: calc(100vw - 48px);
  }
}

.searchDrawer {
  [class="ant-drawer-body"] {
    padding: 12px;
  }
  [class="ant-drawer-content-wrapper"] {
    max-width: 100%;
  }
}

@media (max-width: $mobile_width) {
  .userInfo {
    width: 100vw;
    padding: 0 12px;
  }

  .userInfo .resolveChatButtonsBlock {
    flex-direction: row;
  }

  .userInfoBack {
    display: block !important;
    width: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
    
    svg path {
      fill: $primary_black;
    }

    &.rtl {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .menuIcon {
    display: none;
  }
}

.unsubscribedIcon {
  margin-right: 8px;

  svg {
    color: $primary_red;
  }
}

.unsubscribedTooltipContainer {
  padding: 8px;
}

.unsubscribedTooltipInfo {
  font-size: 12px;
  color: $primary_grey;
  white-space: nowrap;
  margin-bottom: 8px;
}
.unsubscribedTooltipAction {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: $primary_green;
  cursor: pointer;
}

.userInfoExtraDetails {
  display: flex;
  align-items: center;
  gap: 4px;
  color: $secondary_black;
  opacity: 0.9;

  &.inverted {
    color: $primary_grey;
  }
}

.userInfoAccountName {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobileMenuMoreWrapper {
  top: 118px !important;
  left: 24px !important;
  right: 24px !important;
}

.markSolvedMessageContainer {
    display: flex;
    flex-direction: column;
}

.markSolvedMessage {
    font-size: 10px;
    line-height: 8px;
    letter-spacing: -0.02em;
    color: $secondary_black;
}

.dropdownMenu {
  width: calc(100% - 32px);
  left: 16px !important;

  svg {
    width: 16px;
    height: 16px;
  }

  li:not([class="ant-dropdown-menu-item-divider"]) {
    padding-left: 32px;
    height: 40px;
    border-left: 2px solid transparent;
  }
}

.markAsSolvedMobileMenuContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-right: 0 !important;

  button {
    border: none !important;
    border-radius: 0 !important;
    border-left: 1px solid $antd_border !important;
    width: 32px !important;
    padding: 0 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 8px;
  }

  &.disabled {
    .radioOptionBoldText, .radioOptionSubText {
      opacity: 0.25;
    }
  }
}

.assignToMobileMenuContainer {
  margin-left: 0 !important;
  border-left-color: $primary_green !important;
  height: auto !important;
  
  [class="ant-radio-group ant-radio-group-outline"] {
    width: 100%;
  }

  .radioDivider {
    margin: 0 !important;
}
  .radioOption {
    display: grid !important;
    grid-template-columns: auto 1fr auto;
    padding: 8px;
    width: 100%;
    margin: 0;
  
    &:hover {
      background-color: $tertiary_grey;
    }
  }
  
  .radioOptionText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    flex-flow: row wrap;
  
    svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
  
    &.disabled svg {
        cursor: not-allowed;
    }
  }
}

.radioOptionSubText {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: $primary_black;
  opacity: 0.8;
  display: flex !important;
}

.radioOptionBoldText {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: $primary_black;
}

.withBotContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
  }
}
