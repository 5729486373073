@import "../../../../../styles/colorVars";

.templateButtonEdit,
.templateButtonDelete {
  padding: 16px;
  border-radius: 2px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  background-color: $primary_white;
  color: $secondary_black !important;
}

.templateButtonEdit {
  &:hover svg {
    fill: $primary_green_hover;
  }

  &.rtl {
    transform: scaleX(-1);
  }
}

.templateButtonDelete:not([disabled]) {
  &:hover svg {
    fill: $primary_red;
  }
}

.isDeleted[disabled]:hover {
  cursor: not-allowed;
}

.templateMessageField {
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
  row-gap: 8px;
  position: relative;
  padding: 32px 40px;
  border-left: transparent 2px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100px;
  
  &.canManageTemplates {
    cursor: pointer;
  }

  &:hover {
    border-left: $primary_green_hover 2px solid;
    background-color: $primary_grey_bg;

    span {
      color: $secondary_black;
    }
  }

  &.rtl {
    border-left: none;
    border-right: transparent 2px solid;

    &:hover {
      border-right: $primary_green_hover 2px solid;
    }
  }
}

.templateNameSpaceLeft {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &.rtl {
    text-align: right;
  }
}

.templateTitleText {
  font-family: SF UI Text, "Segoe UI";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: $primary_black;
}

.templateIdText {
  font-family: SF UI Text, "Segoe UI";
  font-size: 12px;
  line-height: 14px;
  color: $secondary_black;
}

.templateText {
  font-size: 13px;
  color: #747775;
  display: block;
  width: 100%;
  margin-top: 12px;
  white-space: pre-wrap;
}

.templateTextRtl {
  direction: rtl;
  text-align: right;
}

.singleTemplateRightBlock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: $primary_grey;
  min-height: 70px;
}

.singleTemplateButtons {
  display: flex;
  flex-direction: row;
  max-width: 100px;;
  gap: 8px;
  margin-bottom: 8px;
}

.customSendToWhatsappButton {
  width: 100px;
  height: 46px !important;
  padding: 0px 8px !important;
  min-width: auto !important;
  font-size: 12px !important;
  white-space: wrap !important;
  display: flex !important;
  align-items: center !important;

  [class="ant-btn-loading-icon"] {
    margin-right: 0;
  }
}

.templateStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100px;
}

.templateStatusText {
  margin: 0;
}

.templateStatusTextNew {
  color: $secondary_black;
}

.templateStatusTextPending {
  color: $primary_blue;
}

.templateStatusTextApproved {
  color: $primary_green;
}

.templateStatusTextDeclined {
  color: $primary_red_color;
}

@media (max-width: 800px) {
  .templateNameSpaceLeft {
    width: 78%;
  }
}

@media (max-width: 600px) {
  .templateMessageField {
    flex-direction: column;
  }

  .templateNameSpaceLeft {
    width: 100%;
  }

  .singleTemplateRightBlock {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    align-items: flex-end;
    margin-top: 10px;
  }
}
