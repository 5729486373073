@import "src/styles/numbers";

.blockerContainer {
    background-color:#FAFAFA;
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    z-index: 1;

    border-left: 1px solid #EAEAEA;
    border-right: 1px solid #EAEAEA;

    display: flex;
    justify-content: center;
    align-items: center;
}

.blockerInnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blockerImage {
    height: 60px;
    width: 60px;
}

.blockerTitle {
    margin-top: 25px;
    margin-bottom: 0;
    font-family: SF UI Text,"Segoe UI";
    font-size: 16px;
    line-height: 24px;
    color: #383C3A;
}

.blockerText {
    margin-top: 25px;
    margin-bottom: 0;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #383C3A;
    opacity: 0.7;
}

.blockerButton {
    height: 40px !important;
    margin-top: 32px;
}

@media (max-width: $mobile_width) {
    .blockerContainer {
        display: none;
    }
}
